import React from "react";
import styled from "@emotion/styled";

import logo from "../images/logo.svg";

const LogoImage = styled.img`
  display: inline-block;
  height: ${(props) => (props.size === "small" ? "10px" : "18px")};
  width: ${(props) => (props.size === "small" ? "95px" : "165px")};
  vertical-align: text-bottom;
`;

const Logo = ({ size }) => (
  <LogoImage size={size} src={logo} alt="ultraallinclusive logo" />
);

export default Logo;
